import React from 'react';
import ReactDOM from 'react-dom/client'; // Import from 'react-dom/client'
// import './index.css'; // Import global styles
import App from './App'; // Import the App component

// Create a root using the new API
const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);