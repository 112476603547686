import React, { useState, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './App.css';
import Header from './Header';
import TrendingSearches from './TrendingSearches';
import AutoScrollGifs from './AutoScrollGifs';
import ChatBox from './ChatBox';
import Loader from './Loader'; // Adjust the path as needed
import { ReactComponent as ShareIconSVG } from './share_white.svg';
import { ReactComponent as DownloadIconSVG } from './download_white.svg';
import PostHog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { ReactComponent as VolumeUpIconSVG } from './volume_mute_white.svg';
import { ReactComponent as VolumeOffIconSVG } from './volume_on_white.svg';

// Initialize PostHog
PostHog.init('phc_3vKRfhG7J3ajog17zuEOyY5RundYZjhWJ9Qzx8ZHcUH', {
  api_host: 'https://us.i.posthog.com'
})

const ShareIcon = () => (
  <ShareIconSVG style={{ width: '100%', height: '100%' }} />
);
const DownloadIcon = () => (
  <DownloadIconSVG style={{ width: '100%', height: '100%' }} />
);
const VolumeUpIcon = () => (
  <VolumeUpIconSVG style={{ width: '100%', height: '100%' }} />
);
const VolumeOffIcon = () => (
  <VolumeOffIconSVG style={{ width: '100%', height: '100%' }} />
);

const wsEndpoint = 'wss://hs8q4pd85c.execute-api.ap-southeast-2.amazonaws.com/live/';

function App() {
  const [searchText, setSearchText] = useState('');
  const [error, setError] = useState(false);
  const [mediaItems, setMediaItems] = useState([]); // Replace setGifs with setMediaItems
  const [message, setMessage] = useState('');
  const [ws, setWs] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  const [isSearching, setIsSearching] = useState(false); // state variable to deterine waiting for searching or not
  const [modeSearch, setmodeSearch] = useState(true); // New state for toggle

  const ToggleButton = ({ isOn, onToggle }) => (
    <div className="toggle-container">
      <button
        className={`toggle-button ${isOn ? 'on' : 'off'}`}
        onClick={onToggle}
      >
        <span className={`toggle-option ${!isOn ? 'active' : ''}`}>GIFs</span>
        <span className="toggle-switch"></span>
        <span className={`toggle-option ${isOn ? 'active' : ''}`}>Video</span>
      </button>
    </div>
  );


  const [mutedVideos, setMutedVideos] = useState({});

  const toggleMute = (index) => {
    setMutedVideos(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };



  const connectWebSocket = useCallback((searchTerm, modeSearch) => {
    const websocket = new WebSocket(wsEndpoint);

    websocket.onopen = () => {
      // console.log('Connected to WebSocket API');
      if (searchTerm && modeSearch) {
        websocket.send(JSON.stringify({ action: 'search', data: searchTerm, searchMode: modeSearch }));
      }
    };

    websocket.onmessage = (event) => {
      // console.log('Message from server:', event.data);
      handleServerMessage(event.data);
    };

    websocket.onclose = () => {
      console.log('Disconnected from WebSocket API');
      // setMessage('Disconnected from WebSocket API');
    };

    websocket.onerror = (error) => {
      console.error('WebSocket error:', error);
      // setMessage(`WebSocket error: ${error.message || 'Unknown error'}`);
    };

    setWs(websocket);
  }, []);

  useEffect(() => {
    connectWebSocket();
    // Cleanup function to close the WebSocket connection when the component unmounts
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []); // Empty dependency array ensures this runs only once


  const handleServerMessage = (message) => {
    try {
      const data = JSON.parse(message);
      if (data.update_type === 'gif_created' || data.update_type === 'video_created') {
        setMediaItems((prevItems) => [...prevItems, data]);
      } else if (data.update_type === 'all_gifs_processed' || data.update_type === 'all_videos_processed') {
        setIsSearching(false); // Set to false when all GIFs are processed
      } else {
        console.error('Unexpected message format:', data);
      }
    } catch (error) {
      console.error('Error parsing server message:', error);
      // setMessage(`Error: ${error.message}`);
      setIsSearching(false); // Set to false in case of error
    }
  };

  const handleSearch = (searchTerm, modeSearch) => {

    setSearchText(searchTerm);
    if (searchTerm.trim() === '') {
      setError(true);
      return;
    }

    // Capture the search event
    PostHog.capture('user_performed_search', {
      query: searchTerm
    });

    setError(false);
    setMessage(`Search: ${searchTerm} (mode: ${modeSearch ? 'Video' : 'GIFs'})`);
    setMediaItems([]);
    setHasSearched(true);
    setIsSearching(true); // Set to true when search begins

    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({ action: 'search', data: searchTerm, searchMode: modeSearch }));
    } else {
      // If WebSocket is not open, create a new connection
      connectWebSocket(searchTerm, modeSearch);

    }
  };

  const generateFileName = () => {
    return `tgdm-${uuidv4()}.${modeSearch ? 'mp4' : 'gif'}`;
  };

  const handleDownload = (mediaUrl) => {
    fetch(mediaUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = generateFileName();
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };


  // CHANGED: Updated handleShare function
  const handleShare = async (mediaUrl) => {
    try {
      const response = await fetch(mediaUrl);
      const blob = await response.blob();
      // CHANGED: Use blob.type instead of assuming based on modeSearch
      const file = new File([blob], generateFileName(), { type: blob.type });

      if (navigator.share) {
        await navigator.share({
          files: [file],
          title: `Check out this ${blob.type.startsWith('video') ? 'video' : 'GIF'}!`,
          text: `I found this awesome ${blob.type.startsWith('video') ? 'video' : 'GIF'}!`
        });
      } else {
        // CHANGED: Fallback for unsupported browsers
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = generateFileName();
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(downloadLink.href);
        setMessage('Direct sharing is not supported. The file has been downloaded instead.');
      }
    } catch (error) {
      console.error('Error sharing:', error);
      // CHANGED: Display error to user
      setMessage(`Error sharing: ${error.message}`);
    }
  };


  const handleHomeClick = () => {
    setHasSearched(false);
    setSearchText('');
    setMediaItems([]);
    setMessage('');
  };

  return (
    <PostHogProvider client={PostHog}>
      <div className="container">
        <Header onHomeClick={handleHomeClick} />
        <ChatBox />

        {!hasSearched ? (
          <>
            <div className="search-container">
              <input
                type="text"
                id="searchInput"
                placeholder="Character, scene, dialogue or show..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch(searchText, modeSearch);
                  }
                }}
                className={error ? 'error' : ''}
              />
              <ToggleButton
                isOn={modeSearch}
                onToggle={() => setmodeSearch(!modeSearch)}
              />
              <button id="searchButton" onClick={() => handleSearch(searchText, modeSearch)}></button>
            </div>
            <TrendingSearches onSearch={(term) => handleSearch(term, modeSearch)} modeSearch={modeSearch} />
            <AutoScrollGifs modeSearch={modeSearch} />

          </>
        ) : (

          <div id="results">
            <div id="searchQuery">{message}</div>
            {isSearching ? (
              <div className="loader-container">
                <Loader />
              </div>
            ) : (
              <div id="mediaContainer">
                {mediaItems.slice(0, 10).map((item, index) => (
                  <div key={index} className="mediaItem">
                    {item.update_type === 'gif_created' ? (
                      <img src={item.imageUrl} alt="" />
                    ) : (
                      <video src={item.videoUrl}
                        autoPlay
                        muted={mutedVideos[index] !== false}
                        loop
                        playsInline />
                    )}
                    <div className="mediaActions">
                      {item.update_type !== 'gif_created' && (
                        <button className="action-button" onClick={() => toggleMute(index)}>
                          {mutedVideos[index] !== false ? <VolumeOffIcon /> : <VolumeUpIcon />}
                        </button>
                      )}
                      <button className="action-button" onClick={() => handleShare(item.imageUrl || item.videoUrl)}>
                        <ShareIcon />
                      </button>
                      <button className="action-button" onClick={() => handleDownload(item.imageUrl || item.videoUrl)}>
                        <DownloadIcon />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

        )}

        {hasSearched && (
          <div className="floating-search-container">
            <input
              type="text"
              id="floatingSearchInput"
              placeholder="Character, scene, dialogue or show..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch(searchText, modeSearch);
                }
              }}
              className={error ? 'error' : ''}
            />
            <ToggleButton
              isOn={modeSearch}
              onToggle={() => setmodeSearch(!modeSearch)}
            />
            <button id="floatingSearchButton" onClick={() => handleSearch(searchText, modeSearch)}></button>
          </div>
        )}
      </div>
    </PostHogProvider>
  );
}

export default App;