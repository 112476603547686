import React from 'react';
import logo from './tgdm_rgb.svg';

const Header = ({ onHomeClick }) => {
  return (
    <header className="header">
      <h1 onClick={onHomeClick}>
        <img src={logo} alt="Logo" class="header-logo"></img>
      </h1>
    </header>
  );
};

export default Header;