// ChatBox.js
import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const ChatBox = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');

  const toggleChatBox = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Replace with your EmailJS service ID, template ID, and user ID
    emailjs.send('service_900zjat', 'template_t6rtxdf', { message }, 'b7IkncM-8RsATnUWp')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setMessage('');
        setIsOpen(false);
      })
      .catch((err) => {
        console.error('FAILED...', err);
      });
  };

  return (
    <div>
      <button className={isOpen ? "open-button-close" : "open-button"} onClick={toggleChatBox}>
        {isOpen ? 'close' : 'need more'}
      </button>

      {isOpen && (
        <div className="chat-popup">
          <form className="form-container" onSubmit={handleSubmit}>
            <textarea
              placeholder="Request a feature, more content or something else.."
              name={"User Request"}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
            <button type="submit" className="btn">Send</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default ChatBox;