import React from 'react';

const trendingSearches = [
  'Saul Goodman salesmanship',
  'The Silicon Valley confident Erlich',
  'Staring into the camera The Office ', 
  'Angry George Costanza'
];

const TrendingSearches = ({ onSearch, modeSearch }) => {
  const handleClick = (term) => {
    onSearch(term, modeSearch);
  };

  return (
    <div className="trending-searches">
      {trendingSearches.map(term => (
        <div 
          key={term} 
          className="trending-search-item"
          onClick={() => handleClick(term)}
        >
          {term}
        </div>
      ))}
    </div>
  );
};

export default TrendingSearches;