import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './App.css'; // Make sure this path is correct
import { ReactComponent as DownloadIconSVG } from './download_white.svg';
import { ReactComponent as ShareIconSVG } from './share_white.svg';
import { ReactComponent as VolumeUpIconSVG } from './volume_mute_white.svg';
import { ReactComponent as VolumeOffIconSVG } from './volume_on_white.svg';

const ShareIcon = () => (
  <ShareIconSVG style={{ width: '100%', height: '100%' }} />
);

const DownloadIcon = () => (
  <DownloadIconSVG style={{ width: '100%', height: '100%' }} />
);
const VolumeUpIcon = () => (
  <VolumeUpIconSVG style={{ width: '100%', height: '100%' }} />
);
const VolumeOffIcon = () => (
  <VolumeOffIconSVG style={{ width: '100%', height: '100%' }} />
);


const AutoScrollGifs = ({ modeSearch }) => {
  const [mediaItems, setMediaItems] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);


  const [mutedVideos, setMutedVideos] = useState({});

  const toggleMute = (index) => {
    setMutedVideos(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const endpoint = modeSearch ? 'videos' : 'gifs';
        const response = await fetch(`https://1urkd420i6.execute-api.ap-southeast-2.amazonaws.com/live/${endpoint}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        if (Array.isArray(data)) {
          setMediaItems(data);
        } else if (data && data.body) {
          const bodyData = JSON.parse(data.body);
          if (Array.isArray(bodyData)) {
            setMediaItems(bodyData);
          } else {
            throw new Error('Received data is not an array');
          }
        } else {
          throw new Error('Received data is not in the expected format');
        }
      } catch (e) {
        console.error(`Error fetching ${modeSearch ? 'videos' : 'GIFs'}:`, e);
        setError(e.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMedia();
  }, [modeSearch]);


  const generateFileName = (fileType) => {

    if (fileType === 'gif') {
      return `tgdm-${uuidv4()}.gif`;
  
    } else if (fileType === 'mp4') {
      return `tgdm-${uuidv4()}.mp4`;
    }
  };

  const getFileExtension = (url) => {
    // Remove query parameters, if any
    const cleanUrl = url.split('?')[0];

    // Get the last segment of the URL, which is usually the filename
    const filename = cleanUrl.split('/').pop();

    // Get the extension from the filename
    const extension = filename.split('.').pop();

    return extension;
  };


  const handleDownload = (mediaUrl) => {

    var fileType = 'gif'
    const fileExtension = getFileExtension(mediaUrl)
    if (fileExtension === 'gif') {
      var fileType = 'gif'
    } else if (fileExtension === 'mp4') {
      var fileType = 'mp4'
    }

    console.log('fileType: ',fileType)

    fetch(mediaUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = generateFileName(fileType);
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };

  // const handleDownload = (url) => {

  //   const fileExtension = getFileExtension(url)
  //   console.log('fileExtension',fileExtension)
  //   if (fileExtension === 'gif') {

  //     const fileKey = url.split('?')[0].split('/').slice(3).join('/');
  //     // const endpoint = modeSearch ? 'videos' : 'gifs';
  //     const endpoint = 'gifs';
  //     fetch(`https://1urkd420i6.execute-api.ap-southeast-2.amazonaws.com/live/${endpoint}?download=true&file=${encodeURIComponent(fileKey)}`)
  //       .then(response => {
  //         if (!response.ok) throw new Error('Network response was not ok');

  //         let filename;
  //         const contentDisposition = response.headers.get('Content-Disposition');
  //         if (contentDisposition && contentDisposition.includes('filename=')) {
  //           filename = contentDisposition.split('filename=')[1].replace(/"/g, '');
  //         } else {
  //           filename = generateFileName('gif');
  //         }

  //         return response.blob().then(blob => ({ blob, filename }));
  //       })
  //       .then(({ blob, filename }) => {
  //         const downloadUrl = window.URL.createObjectURL(blob);
  //         const a = document.createElement('a');
  //         a.style.display = 'none';
  //         a.href = downloadUrl;
  //         a.download = filename;
  //         document.body.appendChild(a);
  //         a.click();
  //         window.URL.revokeObjectURL(downloadUrl);
  //         document.body.removeChild(a);
  //       })
  //       .catch(error => {
  //         console.error(`Error downloading ${modeSearch ? 'video' : 'GIF'}:`, error);
  //       });
  //   } else if (fileExtension === 'mp4') {
  //     fetch(url)
  //       .then(response => response.blob())
  //       .then(blob => {
  //         const url = window.URL.createObjectURL(blob);
  //         const a = document.createElement('a');
  //         a.style.display = 'none';
  //         a.href = url;
  //         a.download = generateFileName('mp4');
  //         document.body.appendChild(a);
  //         a.click();
  //         window.URL.revokeObjectURL(url);
  //       });
  //   }

  // };

  const handleShare = async (mediaUrl) => {
    try {
      const response = await fetch(mediaUrl);
      const blob = await response.blob();
      const fileType = blob.type.includes('video') ? 'mp4' : 'gif';

      const file = new File([blob], generateFileName(fileType), { type: blob.type });

      if (navigator.share) {
        await navigator.share({
          files: [file],
          title: `Check out this ${blob.type.includes('video') ? 'video' : 'GIF'}!`,
          text: `I found this awesome ${blob.type.includes('video') ? 'video' : 'GIF'}!`
        });
      } else {
        // Fallback to download if sharing is not supported
        handleDownload(mediaUrl);
        // alert('Sharing is not supported on this device. The file has been downloaded instead.');
      }
    } catch (error) {
      console.error('Error sharing:', error);
      // alert(`Error sharing: ${error.message}`);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // Duplicate the gifs array to create a seamless loop
  const loopedMedia = [...mediaItems, ...mediaItems];

  return (
    <div className="auto-scroll-media">
      <div className="auto-scroll-media-inner">
        {loopedMedia.map((item, index) => (

          <div key={`${item.id}-${index}`} className="auto-scroll-media-item">
            {modeSearch ? (
              <video src={item.url} alt={`Auto-scrolling video ${index + 1}`}
                autoPlay
                muted={mutedVideos[index] !== false}
                loop
                playsInline />
            ) : (
              <img src={item.url} alt={`Auto-scrolling GIF ${index + 1}`} />
            )}
            <div className="mediaActions">
              {modeSearch && (
                <button className="action-button" onClick={() => toggleMute(index)}>
                  {mutedVideos[index] !== false ? <VolumeOffIcon /> : <VolumeUpIcon />}
                </button>
              )}
              <button
                className="action-button"
                onClick={() => handleShare(item.url)}>
                <ShareIcon />
              </button>
              <button
                className="action-button"
                onClick={() => handleDownload(item.url)}
              >
                <DownloadIcon />
              </button>
            </div>
          </div>

        ))}
      </div>
    </div>
  );
};

export default AutoScrollGifs;